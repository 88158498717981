<template>
  <div class="container">
    <vueper-slides
      ref="slide1"
      :slideRatio="3 / 5"
      :touchable="false"
      fade
      :autoplay="false"
      :bullets="false"
      @slide="$refs.slide2.goToSlide($event.currentSlide.index, {emit: false})"
      :arrows="false"
    >
      <!--      fixed-height="40rem"-->
      <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide.image"> </vueper-slide>
    </vueper-slides>

    <vueper-slides
      class="thumbnails"
      ref="slide2"
      :slideRatio="1 / 6"
      @slide="$refs.slide1.goToSlide($event.currentSlide.index, {emit: false})"
      :visible-slides="slideCount"
      :bullets="false"
      :touchable="false"
      :gap="2.5"
      :arrows="false"
      :arrows-outside="true"
    >
      <vueper-slide
        v-for="(slide, i) in slides"
        :key="i"
        :image="slide.image"
        @click.native="$refs.slide2.goToSlide(i)"
      >
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import {VueperSlides, VueperSlide} from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

export default {
  components: {VueperSlides, VueperSlide},
  props: {slides: Array, slideCount: Number, thumbSlideWidth: Number},
};
</script>

<style scoped lang="scss">
.thumbnails {
  //max-width: 50%;
  margin: 1rem auto;
}

.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 1px solid #fff;
  transition: 0.3s ease-in-out;
  opacity: 0.3;
  cursor: pointer;
}

.thumbnails .vueperslide--active {
  opacity: 1;
  border: 6px solid #fd9217;
}
</style>
