<template>
  <div class="top">
    <div class="header">
      <div class="headerimage" />
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center">
        <!--        <div>-->
        <!--          <img src="@/assets/images/pleisure/pleisure_headerimage.jpg" />-->
        <!--        </div>-->
        <div class="col-lg-12 rowSpace"></div>
        <div class="text-center col-lg-12 mainTitle">제주 숲토리텔링 투어</div>
        <div class="mainTitleLine">
          <hr />
        </div>
        <div class="col-lg-12 text-center mainIntro">숲토리텔러와 함께하는 프라이빗 숲 트레킹</div>
        <div class="rowSpace"></div>
        <div class="col buttonArea">
          <router-link to="/p/Pleisure" class="prev-button">이전 </router-link>
          <router-link to="/p/Reservpleisure/6" class="resserve-button"> 예약신청 </router-link>
        </div>
        <div class="rowSpace"><hr /></div>
        <GallerySlideCarousels :slides="main_slides" :slide-count="4" :thumb-slide-width="45" />
      </div>
    </div>
    <div class="container subContainer">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-12 mainInfo">
          <div>
            경이롭고 아름다운 한라산의 자연 속을 걸어보는 시간. 오직 한 팀만을 위한 전담 가이드의 숲토리텔링을 들으며<br />
            자연과 함께 호흡하고 내면의 활력을 깨우는 자연 치유의 시간을 가져보시기 바랍니다.
          </div>
          <div>※ 개인별 기량에 따라 코스는 변경될 수 있습니다.</div>
          <div>※ 필수 복장 : 편한 바지와 운동화(등산화 등)</div>
        </div>
        <div class="rowSpace"><hr /></div>
        <div class="col-lg-12 serviceArea">
          <div class="subTitle">공통 의전 및 서비스</div>
          <div class="subDatail">
            <div>- 차량 의전 서비스 (벤츠 스프린터 / 카니발 하이리무진)</div>
            <div>- 핀크스 의전 담당 매니저 케어</div>
            <div>- 트래킹 Snack&어메니티 제공 및 Kit 대여</div>
            <div>※ Kit : 등산가방, 스틱 등 트래킹 서포트 물품</div>
          </div>
        </div>
        <div class="rowSpace"><hr /></div>
        <div class="col-lg-12 serviceArea">
          <div class="subTitle">메인 프로그램</div>
          <div class="subDatail">
            <div>
              1. 돌오름 Up 코스 / Down 코스 : 편백나무와 삼나무 숲길, 용바위, 판상절리 계곡 등이 포함 된 트래킹 코스
            </div>
            <div>
              2. 시크릿 숲 속 트래킹 : 옛 한라산 등반 코스에 숨겨진 수로길을 따라가는 숲 속 놀이터 같은 힐링 트래킹 코스
            </div>
            <div>
              3. 제주 산바다 트래킹 : 산방산과 형제섬, 마라도와 가파도 그리고 한라산까지 탁 트인 해안 풍광을 즐기는
              트래킹 코스
            </div>
            <div>4. 프라이빗 한라산 트래킹 : 한라산의 대표적인 코스인 어리목으로 입산하여 영실코스로 하산하는 코스</div>
          </div>
          <div class="rowSpace"></div>
          <table class="col-lg-12 table table-bordered subTable">
            <thead>
              <tr>
                <td>옵션</td>
                <td>최대인원</td>
                <td>소요시간</td>
                <td>요금(2인기준)</td>
                <td>비고</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>돌오름 Up/Down 코스</td>
                <td rowspan="4">6인</td>
                <td>3 ~ 3.5H</td>
                <td>400,000</td>
                <td rowspan="4">※ 인원추가 +100,000원/인</td>
              </tr>
              <tr>
                <td>시크릿 숲 속 트레킹</td>
                <td>3 ~ 3.5H</td>
                <td>400,000</td>
              </tr>
              <tr>
                <td>제주 산바다 트레킹</td>
                <td>3 ~ 3.5H</td>
                <td>400,000</td>
              </tr>
              <tr>
                <td>프라이빗 한라산 트레킹</td>
                <td>5 ~ 5.5H</td>
                <td>500,000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="rowSpace"><hr /></div>
      <div class="row d-flex justify-content-center">
        <div class="col-lg-12 serviceArea">
          <div class="subTitle">추가옵션</div>

          <GallerySlideCarousels :slides="addition_slides" :slide-count="3" :thumb-slide-width="50" />

          <table class="col-lg-12 table table-bordered subTable">
            <thead>
              <tr>
                <td>구분</td>
                <td colspan="2">옵션(2인기준)</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>150,000</td>
                <td>수테라피, 사격, 오모로비짜 스파(40분)</td>
                <td rowspan="4">※ 3인 이상 시 전화 문의</td>
              </tr>
              <tr>
                <td>200,000</td>
                <td>메디컬 스파(바디)</td>
              </tr>
              <tr>
                <td>350,000</td>
                <td>오모로비짜 스파(90분)</td>
              </tr>
              <tr>
                <td>플라워 세팅</td>
                <td>300,000 / 500,000 / 700,000</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="rowSpace"><hr /></div>
        <div class="col-lg-12 serviceArea">
          <div class="subTitle">기타사항</div>
          <div class="subDetail">
            <div>※ 5세 이상부터 1인 요금이 적용됩니다.</div>
            <div>※ 경우에 따라 미성년자의 경우에는 보호자 동반이 필요할 수 있습니다.</div>
            <div>※ 단순 고객변심에 의한 취소/환불은 업체 위약 규정에 따라 적용됩니다.</div>
            <div>
              - 7일 전 위약금 0%, 6일 전 ~ 5일 전 30%, 4일 전 50%, 3일 전~2일 전(~17시) 70%, 2일전(17시~) ~ 당일 100%
              위약금 부과
            </div>
            <div>※ 천재지변 및 업체의 운영 상 부득이한 상황으로 취소 시, 위약 규정이 적용되지 않습니다.</div>
            <div>※ 여행자보험 포함 상품입니다.</div>
            <div>※ 요금표 외 기타 인원은 전화 문의</div>
          </div>
        </div>
        <div class="rowSpace"></div>
      </div>
    </div>
  </div>
</template>

<script>
import GallerySlideCarousels from '@/components/GallerySlideCarousels';

export default {
  name: 'forestoytellingtour',
  data() {
    return {
      main_slides: [
        {image: require('@/assets/images/pleisure/pleisure_bodyimage06.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage06-2.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage06-3.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage06-4.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage06-5.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage06-6.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage06-7.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage06-8.jpg')},
      ],
      addition_slides: [
        {image: require('@/assets/images/pleisure/pleisure_bodyimage07.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage08.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage09.jpg')},
      ],
    };
  },
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '숲토리텔링'});
  },
  components: {GallerySlideCarousels},
};
</script>

<style scoped lang="scss">
td {
  vertical-align: middle;
}
.top {
  padding-top: 11rem;
}
.mainTitle {
  font-weight: 800;
  font-size: 2rem;
  letter-spacing: -0.1rem;
}
.mainTitleLine {
  width: 10%;
  > hr {
  }
}
.mainIntro {
}
.buttonArea {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .resserve-button {
    width: 8rem;
    height: 3rem;
    text-align: center;
    line-height: 3rem;
    background-color: #524a3d;
    color: #fff;
  }
  .prev-button {
    justify-content: center;
    width: 8rem;
    height: 3rem;
    text-align: center;
    line-height: 3rem;
    background-color: white;
    color: #000;
    border: 1px solid black;
  }
}
.subContainer {
  width: 100%;
}
.mainInfo {
  padding: 1rem;
  width: 100%;
  height: 100%;
  > div {
    line-height: 1.5rem;
    font-size: 1rem;
  }
}
.serviceArea {
  padding: 1rem;
  > span {
    font-size: 0.8rem;
  }
}
.subTitle {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.subDetail {
  font-size: 0.9rem;
  padding-left: 1rem;
}
.subTable {
  > thead {
    background-color: #eaeaea;

    > tr > td {
      text-align: center;
      font-weight: 600;
    }
  }
  > tbody {
    > tr > td {
      text-align: center;
      font-size: 0.9rem;
    }
  }
}

.rowSpace {
  width: 100%;
  height: 2rem;
  > hr {
  }
}

.headerimage {
  width: 100%;
  margin: 0;
  content: url('~@/assets/images/pleisure/pleisure_headerimage.jpg');
}

@media (max-width: 992px) {
  .top {
    padding-top: 5rem;
  }

  .headerimage {
    display: none;
  }
}
</style>
